import { PageProps, navigate, graphql } from 'gatsby'
import React, { useCallback } from 'react'
import RepairContent from '../components/RepairContent'
import RepairShopMap from '../components/RepairShopMap'
import { RepairShopPageContext } from './RepairShopPage.context'
import { repairSummaryMatchPageBuilder } from './RepairSummaryPage.context'
import { RepairShopQuery } from '../_generated/codegen/graphqlTypes'
import { Helmet } from 'react-helmet'
import RepairShopSelect from '../components/RepairShopSelect'
import fixRepairLabel from '../utils/fixRepairLabel'
import capitalizeFirstLetter from '../utils/capitalizeFirstLetter'

const RepairShopPage: React.FC<
  PageProps<RepairShopQuery, RepairShopPageContext>
> = ({ pageContext, location }) => {
  const pathBuilder = useCallback((shopId: string) => {
    //get url param code
    const segments = location.pathname.split('/')
    segments.pop()
    const code = segments.pop() ?? pageContext.repairCode

    // if no price return undefined
    return repairSummaryMatchPageBuilder(
      pageContext.brandName ?? '',
      pageContext.slug,
      code,
      shopId
    )
  }, [])

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const shopId = e.target.value
      const path = pathBuilder(shopId)
      navigate(path)
    },
    [pathBuilder]
  )

  return (
    <>
      <Helmet>
        <meta
          name="description"
          title={`Venez réparer votre ${capitalizeFirstLetter(
            pageContext.brandName
          )} chez Save !  `}
          content={`Votre ${capitalizeFirstLetter(
            pageContext.brandName
          )} est cassé ? Bénéficiez d'une réparation immédiate réalisée par nos experts agréés, pour changer un écran cassé ou pour une batterie épuisée.`}
        />
      </Helmet>

      <RepairContent>
        <RepairContent.Card
          index={4}
          title={
            <RepairContent.SeoHeader
              floatingOnDesktop
              title={`${fixRepairLabel(
                pageContext.repairCode
              )} de mon téléphone ${capitalizeFirstLetter(
                pageContext.brandName
              )} ${pageContext.modelName}`}
              subtitle="Quel magasin vous convient ?"
            />
          }
        >
          <RepairShopMap
            onChange={handleChange}
            pathBuilder={pathBuilder}
            brandName={pageContext.brandName ?? ''}
          />
        </RepairContent.Card>

        <RepairShopSelect pathBuilder={pathBuilder} />
      </RepairContent>
    </>
  )
}

export const query = graphql`
  query RepairShop($brandName: String, $modelName: String) {
    model: saveModel(brandName: { eq: $brandName }, name: { eq: $modelName }) {
      brandName
      name
      imgUrl
    }
  }
`

export default RepairShopPage
