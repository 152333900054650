import React, { useEffect, useMemo, useRef, useState } from 'react'
import useSaveShopsQuery from '../hooks/useSaveShopsQuery'
import isEmpty from 'lodash/isEmpty'
import styled from 'styled-components'
import Form, { selectStyle } from './Form'
import { useClickAway, useDebounce } from 'react-use'
import { Link } from 'gatsby'
import searchRegexp from '../utils/searchRegexp'

const SelectContainer = styled.div`
  ${selectStyle}
  background-position-y: 10px;
  cursor: default;
  flex-direction: column;
  gap: 10px;
  display: flex;

  .RepairShopSelect_Input,
  .RepairShopSelect_Results {
    display: none;
  }

  .RepairShopSelect_Input[data-show='true'],
  .RepairShopSelect_Results[data-show='true'] {
    display: block;
  }
`

const Button = styled.button`
  display: block;
  width: calc(100% + 18px);
  text-align: left;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  margin: -6px -9px -6px -9px;
  padding: 6px 9px 6px 9px;
`

const Input = styled(Form.Input)`
  input {
    padding: 8px 8px;
  }
`

const Results = styled.div``

const OptionLink = styled(Link)`
  color: ${({ theme }) => theme.colors.body};
  text-decoration: none;
  font-size: 13px;
  padding: 6px 0px;
  display: block;
  line-height: 16px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  :hover  {
    background-color: ${({ theme }) => theme.colors.background};
  }
`

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.body};
  font-style: italic;
  font-size: 13px;
  line-height: 16px;
  padding: 10px 0;
  text-align: center;
`

interface RepairShopSelectProps {
  pathBuilder?: (shopId: string) => string | undefined
}

const RepairShopSelect: React.FC<RepairShopSelectProps> = ({ pathBuilder }) => {
  const shops = useSaveShopsQuery()
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef(null)
  const [show, setShow] = useState(false)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState('')

  const items = useMemo(
    () =>
      shops
        .map((shop) => ({
          id: shop.shopId,
          label: `(${shop.shopId}) ${shop.shopName} • ${shop.shopFullAddress}`,
          url: pathBuilder && shop.shopId && pathBuilder(shop.shopId),
        }))
        .filter((x) => !isEmpty(x.url)),
    [shops, pathBuilder]
  )

  const filteredItems = useMemo(
    () =>
      items.filter((x) =>
        debouncedSearch.trim().length === 0
          ? true
          : x.label.match(searchRegexp(debouncedSearch))
      ),
    [items, debouncedSearch]
  )

  useDebounce(
    () => {
      setDebouncedSearch(search)
    },
    300,
    [search]
  )

  useEffect(() => {
    if (show) {
      inputRef.current?.focus()
    } else {
      setSearch('')
      setDebouncedSearch('')
    }
  }, [show, inputRef.current])

  useClickAway(containerRef, () => {
    setShow(false)
  })

  return (
    <SelectContainer className="RepairShopSelect" ref={containerRef}>
      <Button onClick={() => setShow((s) => !s)}>
        Sélectionnez une boutique
      </Button>

      <Input
        ref={inputRef}
        className="RepairShopSelect_Input"
        data-show={show}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <Results className="RepairShopSelect_Results" data-show={show}>
        {filteredItems.length > 0 ? (
          <ul>
            {filteredItems.map((shop) => (
              <li key={shop.id}>
                <OptionLink
                  to={shop.url ?? '#'}
                  dangerouslySetInnerHTML={{
                    __html:
                      debouncedSearch.length > 0
                        ? shop.label.replace(
                            searchRegexp(debouncedSearch),
                            `<mark>$1</mark>`
                          )
                        : shop.label,
                  }}
                ></OptionLink>
              </li>
            ))}
          </ul>
        ) : (
          <Empty>Aucun magasin ne correspond à votre recherche</Empty>
        )}
      </Results>
    </SelectContainer>
  )
}

export default RepairShopSelect
