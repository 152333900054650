import { RepairSummaryQueryVariables } from '../_generated/codegen/graphqlTypes'
import { repairShopPageBuilder } from './RepairShopPage.context'

export const repairSummaryPageStepLabel = 'Mon récap'

export type RepairSummaryPageContext = RepairSummaryQueryVariables & {
  modelSlug: string
  repairCode: string
}

export const repairSummaryMatchPageBuilder = (
  brandName: string,
  slug: string,
  repairCode: string,
  shopId: string
) => {
  return `${repairShopPageBuilder(brandName, slug, repairCode)}${shopId}/`
}
